import React from 'react';
import { CustomLogoProps } from './types';
import iconLogo from './icon-logo.svg';

const IconLogo = ({
  customIconLogo,
}: {
  customIconLogo: CustomLogoProps | undefined;
}) => {
  if (customIconLogo?.url) {
    return (
      <img
        style={{ maxHeight: '45px', maxWidth: '32px' }}
        src={customIconLogo.url}
        alt="custom icon logo"
      />
    );
  }
  return (
    <img
      style={{ maxHeight: '45px', maxWidth: '32px' }}
      src={iconLogo}
      alt="icon logo datamesh"
    />
  );
};

export default IconLogo;
