import { usePublishEvent } from '@agilelab/plugin-wb-events';
import { marketplaceConsumableInterfaceNavigatedTopic } from '@agilelab/plugin-wb-events-common';
import { Descriptor } from '@agilelab/plugin-wb-marketplace-common';
import { CustomView, useDialog } from '@agilelab/plugin-wb-platform';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { SemanticLinkingTable } from '../OutputPortPage/OverviewPage/SemanticLinkingTable';
import { CustomViews } from './index';

export const OutputPortPage = (props: {
  data: Record<string, any>;
  actions?: Record<string, any>;
}) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [subcomponent, setSubcomponent] = useState(undefined);
  const data = props.data;
  const semanticLinkingDialog = useDialog({ title: 'Semantic Linking' });
  const { publish } = usePublishEvent({
    generatorSystem: 'marketplace',
    keyMarketplace: data?.componentInstance?.descriptor?.id,
  });

  const location = useLocation();

  useEffect(() => {
    if (data?.componentInstance?.descriptor?.id) {
      publish(marketplaceConsumableInterfaceNavigatedTopic, {
        path: location.pathname,
        entityUrn: data?.componentInstance?.descriptor?.id,
      });
    }
  }, [location.pathname, data?.componentInstance?.descriptor?.id, publish]);

  return (
    <>
      <CustomViews.SubcomponentDrawer
        open={openDrawer}
        setOpen={setOpenDrawer}
        subcomponent={subcomponent}
      />
      <CustomView
        id="marketplace_component"
        typeId={data.componentInstance?.descriptor?.kind.toLowerCase()}
        templateId={data.componentInstance?.descriptor?.useCaseTemplateId || ''}
        actions={
          props.actions || {
            showSubcomponent: (component: any) => {
              setOpenDrawer(true);
              setSubcomponent(component);
            },
            showSemanticLink: async (field: any) => {
              await semanticLinkingDialog(
                <SemanticLinkingTable
                  descriptor={data as Descriptor}
                  schema={field}
                />,
              );
            },
          }
        }
        data={data}
      />
    </>
  );
};
