import {
  AdditionalFilters,
  ComputePolicyViolationsCountRequest,
  FailedPolicyEvaluationStats,
  FailedPolicyEvaluationStatsRequest,
  GetMetricResultsRequest,
  GetPolicyViolationsRequest,
  MetricCreate,
  MetricEdit,
  MetricResults,
  PolicyCreate,
  PolicyEdit,
  PolicyExecutionStats,
  PolicyExecutionStatsRequest,
  PolicyViolationOutcome,
  PolicyViolations,
  PolicyViolationsCount,
  PolicyViolationsCountMap,
  Status,
  TopFailedPoliciesRequest,
  TopFailedPolicyInsight,
} from '@agilelab/plugin-wb-governance-common';

import {
  encodeQueryParams,
  handleFailedResponse,
} from '@agilelab/plugin-wb-platform-common';
import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import crossFetch from 'cross-fetch';

export declare type GovernanceRequestOptions = {
  token?: string;
};

export interface GovernanceApi {
  createPolicy(
    policyData: PolicyCreate,
    options?: GovernanceRequestOptions,
  ): Promise<string>;

  updatePolicy(
    id: string,
    policyData: PolicyEdit,
    options?: GovernanceRequestOptions,
  ): Promise<string>;

  updatePolicyStatus(
    id: string,
    status: Status,
    options?: GovernanceRequestOptions,
  ): Promise<void>;

  createNewPolicyVersion(
    id: string,
    options?: GovernanceRequestOptions,
  ): Promise<void>;

  launchTestPolicy(
    id: string,
    additionalFilters?: AdditionalFilters,
    options?: GovernanceRequestOptions,
  ): Promise<{ reportId: string }>;

  createMetric(
    metricData: MetricCreate,
    options?: GovernanceRequestOptions,
  ): Promise<string>;

  updateMetric(
    id: string,
    metricData: any,
    options?: GovernanceRequestOptions,
  ): Promise<string>;

  updateMetricStatus(
    id: string,
    status: Status,
    options?: GovernanceRequestOptions,
  ): Promise<void>;

  createNewMetricVersion(
    id: string,
    options?: GovernanceRequestOptions,
  ): Promise<void>;

  launchTestMetric(
    id: string,
    additionalFilters?: AdditionalFilters,
    options?: GovernanceRequestOptions,
  ): Promise<{ reportId: string }>;

  getTopFailedPolicies(
    params: TopFailedPoliciesRequest,
    options?: GovernanceRequestOptions,
  ): Promise<TopFailedPolicyInsight[]>;

  getPolicyExecutionStats(
    params: PolicyExecutionStatsRequest,
    options?: GovernanceRequestOptions,
  ): Promise<PolicyExecutionStats[]>;

  getFailedPolicyEvaluationStats(
    params: FailedPolicyEvaluationStatsRequest,
    options?: GovernanceRequestOptions,
  ): Promise<FailedPolicyEvaluationStats[]>;

  computePolicyViolationsCount(
    params: ComputePolicyViolationsCountRequest,
    options?: GovernanceRequestOptions,
  ): Promise<PolicyViolationsCountMap>;

  getPolicyViolations(
    params: GetPolicyViolationsRequest,
    options?: GovernanceRequestOptions,
  ): Promise<PolicyViolations>;

  getMetricResults(
    params: GetMetricResultsRequest,
    options?: GovernanceRequestOptions,
  ): Promise<MetricResults>;
}

export class GovernanceClient implements GovernanceApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;
  private readonly baseUrlPromise: Promise<string>;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi?: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.baseUrlPromise = this.discoveryApi.getBaseUrl('governance');
    this.fetchApi = options.fetchApi || { fetch: crossFetch };
  }

  private async getCredentials(
    options?: GovernanceRequestOptions,
  ): Promise<string> {
    return options?.token ?? '';
  }

  async createPolicy(
    policyData: PolicyCreate,
    options?: GovernanceRequestOptions,
  ): Promise<string> {
    const baseUrl = await this.baseUrlPromise;

    const response = await this.fetchApi.fetch(`${baseUrl}/policies`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${await this.getCredentials(options)}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(policyData),
    });
    await handleFailedResponse(response);

    return response.json();
  }

  async updatePolicy(
    id: string,
    policyData: PolicyEdit,
    options?: GovernanceRequestOptions,
  ): Promise<any> {
    const baseUrl = await this.baseUrlPromise;

    const response = await this.fetchApi.fetch(`${baseUrl}/policies/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${await this.getCredentials(options)}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(policyData),
    });
    await handleFailedResponse(response);

    return response.json();
  }

  async updatePolicyStatus(
    id: string,
    status: Status,
    options?: GovernanceRequestOptions,
  ): Promise<any> {
    const baseUrl = await this.baseUrlPromise;

    const response = await this.fetchApi.fetch(
      `${baseUrl}/policies/${id}/status`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${await this.getCredentials(options)}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status }),
      },
    );
    await handleFailedResponse(response);

    return response.json();
  }

  async createNewPolicyVersion(
    id: string,
    options?: GovernanceRequestOptions,
  ): Promise<any> {
    const baseUrl = await this.baseUrlPromise;

    const response = await this.fetchApi.fetch(
      `${baseUrl}/policies/${id}/new-version`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${await this.getCredentials(options)}`,
          'Content-Type': 'application/json',
        },
      },
    );
    await handleFailedResponse(response);

    return response.json();
  }

  async launchTestPolicy(
    id: string,
    additionalFilters: AdditionalFilters,
    options?: GovernanceRequestOptions,
  ): Promise<{ reportId: string }> {
    const baseUrl = await this.baseUrlPromise;

    const response = await this.fetchApi.fetch(
      `${baseUrl}/policies/${id}/test`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${await this.getCredentials(options)}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(additionalFilters),
      },
    );
    await handleFailedResponse(response);

    return response.json();
  }

  async createMetric(
    metricData: MetricCreate,
    options?: GovernanceRequestOptions | undefined,
  ): Promise<string> {
    const baseUrl = await this.baseUrlPromise;

    const response = await this.fetchApi.fetch(`${baseUrl}/metrics`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${await this.getCredentials(options)}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(metricData),
    });
    await handleFailedResponse(response);

    return response.json();
  }

  async updateMetric(
    id: string,
    metricData: MetricEdit,
    options?: GovernanceRequestOptions,
  ): Promise<any> {
    const baseUrl = await this.baseUrlPromise;

    const response = await this.fetchApi.fetch(`${baseUrl}/metrics/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${await this.getCredentials(options)}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(metricData),
    });
    await handleFailedResponse(response);

    return response.json();
  }

  async updateMetricStatus(
    id: string,
    status: Status,
    options?: GovernanceRequestOptions,
  ): Promise<any> {
    const baseUrl = await this.baseUrlPromise;

    const response = await this.fetchApi.fetch(
      `${baseUrl}/metrics/${id}/status`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${await this.getCredentials(options)}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status }),
      },
    );
    await handleFailedResponse(response);

    return response.json();
  }

  async createNewMetricVersion(
    id: string,
    options?: GovernanceRequestOptions,
  ): Promise<any> {
    const baseUrl = await this.baseUrlPromise;

    const response = await this.fetchApi.fetch(
      `${baseUrl}/metrics/${id}/new-version`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${await this.getCredentials(options)}`,
          'Content-Type': 'application/json',
        },
      },
    );
    await handleFailedResponse(response);

    return response.json();
  }

  async launchTestMetric(
    id: string,
    additionalFilters: AdditionalFilters,
    options?: GovernanceRequestOptions | undefined,
  ): Promise<{ reportId: string }> {
    const baseUrl = await this.baseUrlPromise;

    const response = await this.fetchApi.fetch(
      `${baseUrl}/metrics/${id}/test`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${await this.getCredentials(options)}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(additionalFilters),
      },
    );
    await handleFailedResponse(response);

    return response.json();
  }

  async getTopFailedPolicies(
    params: TopFailedPoliciesRequest,
    options?: GovernanceRequestOptions,
  ): Promise<TopFailedPolicyInsight[]> {
    const baseUrl = await this.baseUrlPromise;

    const response = await this.fetchApi.fetch(
      `${baseUrl}/policies/stats/top-failed`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${await this.getCredentials(options)}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      },
    );
    await handleFailedResponse(response);

    return response.json();
  }

  async getPolicyExecutionStats(
    params: PolicyExecutionStatsRequest,
    options?: GovernanceRequestOptions,
  ): Promise<PolicyExecutionStats[]> {
    const baseUrl = await this.baseUrlPromise;

    const response = await this.fetchApi.fetch(
      `${baseUrl}/policies/stats/executions`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${await this.getCredentials(options)}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      },
    );
    await handleFailedResponse(response);

    return response.json();
  }

  async getFailedPolicyEvaluationStats(
    params: FailedPolicyEvaluationStatsRequest,
    options?: GovernanceRequestOptions,
  ): Promise<FailedPolicyEvaluationStats[]> {
    const baseUrl = await this.baseUrlPromise;

    const response = await this.fetchApi.fetch(
      `${baseUrl}/policies/stats/executions/failures`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${await this.getCredentials(options)}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      },
    );
    await handleFailedResponse(response);

    return response.json();
  }

  async computePolicyViolationsCount(
    params: ComputePolicyViolationsCountRequest,
    options?: GovernanceRequestOptions,
  ): Promise<PolicyViolationsCountMap> {
    const baseUrl = await this.baseUrlPromise;
    const response = await this.fetchApi.fetch(
      `${baseUrl}/resources/policy-violations/count`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${await this.getCredentials(options)}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      },
    );
    await handleFailedResponse(response);

    const policyViolationsCount =
      (await response.json()) as PolicyViolationsCount;

    const result: PolicyViolationsCountMap = new Map();

    policyViolationsCount.items.forEach(item => {
      const resourceMap: Map<PolicyViolationOutcome, number> = new Map();
      item.violations.forEach(violation => {
        resourceMap.set(violation.outcome, violation.count);
      });
      result.set(item.resource, resourceMap);
    });

    return result;
  }

  async getPolicyViolations(
    params: GetPolicyViolationsRequest,
    options?: GovernanceRequestOptions,
  ): Promise<PolicyViolations> {
    const baseUrl = await this.baseUrlPromise;
    const queryParams = encodeQueryParams({
      env: params.environment,
      policies: params.policies?.join(','),
      timing: params.timing,
    });
    const response = await this.fetchApi.fetch(
      `${baseUrl}/resources/${params.resource}/policy-violations${queryParams}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${await this.getCredentials(options)}`,
          'Content-Type': 'application/json',
        },
      },
    );
    await handleFailedResponse(response);
    return response.json();
  }

  async getMetricResults(
    params: GetMetricResultsRequest,
    options?: GovernanceRequestOptions,
  ): Promise<MetricResults> {
    const baseUrl = await this.baseUrlPromise;
    const queryParams = encodeQueryParams({
      env: params.environment,
      metrics: params.metrics?.join(','),
      timing: params.timing,
    });
    const response = await this.fetchApi.fetch(
      `${baseUrl}/resources/${params.resource}/metric-results${queryParams}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${await this.getCredentials(options)}`,
          'Content-Type': 'application/json',
        },
      },
    );
    await handleFailedResponse(response);
    return response.json();
  }
}
