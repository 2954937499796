/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  EnvironmentApi,
  environmentApiRef,
} from '@agilelab/plugin-wb-environment';
import {
  compareEnvironments,
  Environment,
} from '@agilelab/plugin-wb-platform-common';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {
  loadFiltersFromSessionStorage,
  saveFiltersToSessionStorage,
} from '../session';

export interface EnvironmentsContextType {
  environment: Environment;
  environmentList: Environment[];
  setEnvironment: (newEnv: Environment) => void;
  disabledStatus: boolean;
  setDisabledStatus: React.Dispatch<React.SetStateAction<boolean>>;
  priorityEnvironment: Environment;
}

const noEnv: Environment = {
  name: '',
  priority: 0,
};

export const EnvironmentsContext = React.createContext<EnvironmentsContextType>(
  {} as EnvironmentsContextType,
);

export interface EnvironmentsContextProviderProps {
  children?: React.ReactNode;
}

export const EnvironmentsContextProvider: React.FC<
  EnvironmentsContextProviderProps
> = ({ children }) => {
  const sessionItem = 'environment';
  const environmentApi: EnvironmentApi = useApi(environmentApiRef);
  const identityApi = useApi(identityApiRef);
  const loadedEnv = loadFiltersFromSessionStorage<Environment>(sessionItem);
  const initialEnv = loadedEnv ?? noEnv;
  const [disabledStatus, setDisabledStatus] = useState<boolean>(false);
  const [environment, setEnv] = useState<Environment>(initialEnv);
  const [environmentList, setEnvironmentList] = useState<Environment[]>([]);
  const [priorityEnvironment, setPriorityEnvironment] =
    useState<Environment>(initialEnv);
  const setEnvironment = useCallback((newEnv: Environment) => {
    saveFiltersToSessionStorage<Environment>(sessionItem, _ => newEnv);
    setEnv(newEnv);
  }, []);

  const [fetchEnvironmentsState, fetchEnvironments] = useAsyncFn(async () => {
    const credentials = await identityApi.getCredentials();
    return environmentApi.getEnvironments(credentials);
  }, [identityApi, environmentApi]);

  useEffect(() => {
    fetchEnvironments();
  }, [fetchEnvironments]);

  useEffect(() => {
    if (
      fetchEnvironmentsState.error ||
      fetchEnvironmentsState.loading ||
      !fetchEnvironmentsState.value
    ) {
      return;
    }
    const envs = fetchEnvironmentsState.value.sort(compareEnvironments) ?? [];
    setEnvironmentList(envs);
    if (environment.name === noEnv.name) {
      const priorityEnv = envs.at(0);
      if (priorityEnv) {
        setEnvironment(priorityEnv);
        setPriorityEnvironment(priorityEnv);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchEnvironmentsState]);

  const contextValue = useMemo(
    () => ({
      environment,
      environmentList,
      setEnvironment: (newEnv: Environment) => {
        try {
          setEnvironment(newEnv);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      },
      disabledStatus,
      setDisabledStatus,
      priorityEnvironment,
    }),
    [
      environment,
      environmentList,
      setEnvironment,
      disabledStatus,
      setDisabledStatus,
      priorityEnvironment,
    ],
  );

  return (
    <EnvironmentsContext.Provider value={contextValue}>
      {children}
    </EnvironmentsContext.Provider>
  );
};

export const useEnvironmentsContext = () => useContext(EnvironmentsContext);
