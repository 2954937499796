import { Environment } from '../types';

/**
 * This function is used to compare two environments.
 * We use this only for sorting the environments in the UI,
 * as the other external environments depend on them.
 * @param a
 * @param b
 */
export function compareEnvironments(a: Environment, b: Environment) {
  function hasPriority(env: Environment): boolean {
    return env.priority !== undefined;
  }

  if (hasPriority(a) && hasPriority(b)) {
    return a.priority! - b.priority!; // Smaller is first
  }
  if (hasPriority(a)) {
    return -1;
  }
  if (hasPriority(b)) {
    return 1;
  }
  return a.name.localeCompare(b.name);
}
