import React from 'react';
import { getCustomLogo } from './utils';
import { CustomLogoProps } from './types';
import { DefaultLogo } from './DefaultLogo';
import { CustomLogo } from './CustomLogo';
import { Skeleton } from '@material-ui/lab';
import IconLogo from './IconLogo';

const LOGO_PATH = 'logo';
const ICON_LOGO_PATH = 'icon-logo';

type LogoProps = {
  full?: boolean;
};

export const Logo = ({ full = true }: LogoProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [logos, setLogos] = React.useState<{
    fullLogo?: CustomLogoProps;
    iconLogo?: CustomLogoProps;
  }>({
    fullLogo: undefined,
    iconLogo: undefined,
  });

  const loadLogos = async () => {
    setLoading(true);
    const [fullLogoResult, iconLogoResult] = await Promise.allSettled([
      getCustomLogo(LOGO_PATH),
      getCustomLogo(ICON_LOGO_PATH),
    ]);

    const newLogos: { fullLogo?: CustomLogoProps; iconLogo?: CustomLogoProps } =
      {};

    if (fullLogoResult.status === 'fulfilled') {
      newLogos.fullLogo = fullLogoResult.value;
    }

    if (iconLogoResult.status === 'fulfilled') {
      newLogos.iconLogo = iconLogoResult.value;
    }
    setLogos(newLogos);
    setLoading(false);
  };

  React.useEffect(() => {
    loadLogos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <Skeleton style={{ minWidth: '190px' }} width="100%" height="100%" />
    );
  }

  if (!full) {
    return <IconLogo customIconLogo={logos.iconLogo} />;
  }

  return logos.fullLogo ? (
    <CustomLogo customLogo={logos.fullLogo} />
  ) : (
    <DefaultLogo />
  );
};
