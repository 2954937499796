import { entityRefToUrn } from '@agilelab/plugin-wb-builder-common';
import { governanceApiRef } from '@agilelab/plugin-wb-governance';
import {
  PolicyViolationsCountMap,
  Timing,
} from '@agilelab/plugin-wb-governance-common';
import {
  decodeBase64PageCursor,
  encodeBase64PageCursor,
  EnvironmentsContextType,
  useEnvironmentsContext,
  useQueryParamState,
} from '@agilelab/plugin-wb-platform';
import {
  ALL_TAXONOMIES_FILTER,
  practiceShaperApiRef,
  useTaxonomySelection,
} from '@agilelab/plugin-wb-practice-shaper';
import {
  adaptFilters,
  FilterResolversOverride,
  FilterState,
  SearchFiltersContext,
  useFilterStateValidation,
  useSearchFilterConfig,
  useSearchFilters,
  witboostSearchApiRef,
} from '@agilelab/plugin-wb-search';
import {
  CollatorType,
  ComplexFilter,
  JsonObjectSearchFilterVisitor,
  MARKETPLACE_FAVORITES,
  MARKETPLACE_PROJECTS_CONSUMABLE,
  MARKETPLACE_PROJECTS_DATA_LANDSCAPE,
  MARKETPLACE_PROJECTS_ENVIRONMENT,
  OrderByOption,
  QueryOperator,
  SearchFilterConfigType,
  WitboostMarketplaceSearchResultSet,
} from '@agilelab/plugin-wb-search-common';
import { useApolloClient } from '@apollo/client';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { useStarredEntities } from '@backstage/plugin-catalog-react';
import { isEqual } from 'lodash';
import {
  createContext,
  default as React,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import usePrevious from 'react-use/esm/usePrevious';
import useAsync, { AsyncState } from 'react-use/lib/useAsync';
import { buildDomainTrees, getDomainTreesByTypes } from '../../../utils';
import { MarketplaceSearchDocumentEnrichedResultSet } from '../types';
import {
  enrichSearchResults,
  getCompatibleDomainTypes,
  typeFilterResolver,
} from '../utils';

type Search = {
  view: ViewType;
  setView: (newParams: ViewType) => void;
  environment: EnvironmentsContextType['environment'];
  policyViolationsInfo: AsyncState<PolicyViolationsCountMap>;
  limit: number;
  setLimit: (limit: number) => void;
  cursor?: string;
  filterStateValid?: boolean;
  setCursor: React.Dispatch<React.SetStateAction<string | undefined>>;
  filters?: FilterState | undefined;
  setFilters: React.Dispatch<React.SetStateAction<FilterState | undefined>>;
  query?: string;
  setQuery: (query: string) => void;
  filtersContext: SearchFiltersContext;
  favoriteFilter: boolean;
  setFavoriteFilter: React.Dispatch<React.SetStateAction<boolean>>;
  setOrderByOption: React.Dispatch<React.SetStateAction<OrderByOption>>;
  orderByOption: OrderByOption;
} & AsyncState<MarketplaceSearchDocumentEnrichedResultSet>;

const defaultSearchContext = null;

export const SearchContext = createContext<Search | null>(defaultSearchContext);

const DEFAULT_LIMIT = 15;

// consumable should ALWAYS be true
const DEFAULT_FILTERS = {
  [MARKETPLACE_PROJECTS_CONSUMABLE.label]: 'true',
  [MARKETPLACE_FAVORITES.label]: 'false',
};

export const useSearchContext = () => {
  const contextValue = useContext(SearchContext);

  if (!contextValue) {
    throw new Error(
      'useSearchContext has to be used within <SearchContext.Provider>',
    );
  }

  return contextValue;
};

type ViewType = 'cards' | 'table';

export const SearchContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const searchApi = useApi(witboostSearchApiRef);
  const apolloClient = useApolloClient();
  const filtersConfig = useSearchFilterConfig();
  const practiceShaperApi = useApi(practiceShaperApiRef);
  const identityApi = useApi(identityApiRef);

  const { selectedTaxonomyRef } = useTaxonomySelection();

  const filterResolversOverride = useMemo<FilterResolversOverride>(
    () => ({
      [SearchFilterConfigType.DOMAIN]: async values => {
        const compatibleDomainTypes = await getCompatibleDomainTypes({
          selectedTaxonomyRef,
          practiceShaperApi,
          identityApi,
        });
        const data = await getDomainTreesByTypes(
          compatibleDomainTypes ?? [],
          apolloClient,
        );
        return filter => {
          const availableDomains = new Set(
            values[filter.label].map(v => v.value),
          );

          // filters out domains which are not in available values
          return buildDomainTrees(data, d => availableDomains.has(d.id));
        };
      },
      [SearchFilterConfigType.TYPE]: async values => {
        const data = await typeFilterResolver(
          practiceShaperApi,
          selectedTaxonomyRef !== ALL_TAXONOMIES_FILTER
            ? selectedTaxonomyRef
            : undefined,
        );
        return filter => {
          if (!values || !values[filter.label]) {
            return [];
          }
          const availableTypes = new Map(
            values[filter.label].map(v => [v.value, v]),
          );
          // filters out types which are not in available values
          return data.filter(i => availableTypes.has(i.value));
        };
      },
    }),
    [apolloClient, identityApi, practiceShaperApi, selectedTaxonomyRef],
  );

  const [filters, setFilters] = useQueryParamState<FilterState | undefined>(
    'filters',
  );

  const [orderByOption, setOrderByOption] =
    useQueryParamState<OrderByOption>('orderBy');

  const [limit, setLimit] = useQueryParamState<string | undefined>('limit');

  const setLimitNumber = useCallback(
    (newLimit: number) => {
      setLimit(newLimit.toString());
    },
    [setLimit],
  );

  const [cursor, setCursor] = useQueryParamState<string | undefined>('cursor');

  const [favoriteFilter, setFavoriteFilter] = useState<boolean>(false);

  const [view, setView] = useQueryParamState<ViewType>('view');

  const [query, setQuery] = useQueryParamState<string>('query');

  const governanceApi = useApi(governanceApiRef);

  const { environment } = useEnvironmentsContext();

  // merge the values for taxonomy, environment and consumable with the filters
  const completeFilters = useMemo(() => {
    // add environment and favorites since they are handled outside filters
    const extraValues = {
      [MARKETPLACE_PROJECTS_ENVIRONMENT.label]: [environment.name],
      [MARKETPLACE_FAVORITES.label]: favoriteFilter.toString(),
    };

    // add taxonomy since it's handled outside filters
    if (selectedTaxonomyRef !== ALL_TAXONOMIES_FILTER)
      extraValues[MARKETPLACE_PROJECTS_DATA_LANDSCAPE.label] = [
        entityRefToUrn(selectedTaxonomyRef),
      ];

    return { ...DEFAULT_FILTERS, ...extraValues, ...filters };
  }, [filters, selectedTaxonomyRef, environment, favoriteFilter]);

  const prevCompleteFilters = usePrevious(completeFilters);

  const prevQuery = usePrevious(query);

  const prevLimit = usePrevious(limit);

  useEffect(() => {
    if (
      prevCompleteFilters === undefined ||
      isEqual(completeFilters, prevCompleteFilters)
    )
      return;
    setCursor(old => {
      if (decodeBase64PageCursor(old) > 0) return encodeBase64PageCursor(0);
      return old;
    });
  }, [completeFilters, prevCompleteFilters, setCursor]);

  // Any time the query term changes, we want to start from page 0.
  // Only reset if it has been modified by the user at least once, the initial state must not reset
  useEffect(() => {
    if (prevQuery === undefined || query === prevQuery) return;
    setCursor(old => {
      if (decodeBase64PageCursor(old) > 0) return encodeBase64PageCursor(0);
      return old;
    });
  }, [prevQuery, query, setCursor]);

  // Any time the limit changes, we want to start from page 0.
  // Only reset if it has been modified by the user at least once, the initial state must not reset
  useEffect(() => {
    if (prevLimit === undefined || limit === prevLimit) return;
    setCursor(old => {
      if (decodeBase64PageCursor(old) > 0) return encodeBase64PageCursor(0);
      return old;
    });
  }, [prevLimit, limit, setCursor]);

  const { starredEntities } = useStarredEntities();

  const jsonSearchFilter = useMemo(() => {
    const adaptedFilters = adaptFilters(completeFilters ?? {}, filtersConfig, [
      ...starredEntities,
    ]);

    return new ComplexFilter(QueryOperator.AND, [...adaptedFilters]).accept(
      new JsonObjectSearchFilterVisitor(),
    );
  }, [completeFilters, filtersConfig, starredEntities]);

  const filtersContext = useSearchFilters(
    CollatorType.MARKETPLACE_PROJECTS,
    filterResolversOverride,
    jsonSearchFilter,
    query,
  );

  // make a query to searchApi whenever the search params change
  const result = useAsync(async () => {
    const resultSet = (await searchApi.query({
      term: query ?? '',
      pageCursor: cursor,
      pageLimit: limit ? Number(limit) : DEFAULT_LIMIT,
      filters: jsonSearchFilter,
      orderBy: orderByOption ?? { field: 'rank', order: 'desc' },
      types: [CollatorType.MARKETPLACE_PROJECTS],
    })) as WitboostMarketplaceSearchResultSet;

    const enrichedResultSet: MarketplaceSearchDocumentEnrichedResultSet = {
      ...resultSet,
      results: await enrichSearchResults(
        resultSet.results,
        apolloClient,
        practiceShaperApi,
      ),
    };
    // TODO analytics?
    return enrichedResultSet;
  }, [query, completeFilters, filtersConfig, cursor, limit, orderByOption]);

  // Keeps the filter state valid against the available values of every filter;
  const filterStateValid = useFilterStateValidation(
    filters,
    setFilters,
    filtersContext.availableValues,
    filtersConfig,
  );

  // add policy violations info
  const policyViolationsInfo: AsyncState<PolicyViolationsCountMap> =
    useAsync(async () => {
      const ids = result.value?.results
        .filter(r => r.document.id)
        .map(r => r.document.id);
      if (ids && ids.length)
        return governanceApi.computePolicyViolationsCount(
          {
            environment: environment.name,
            // use a set to make sure each id is distinct
            resources: Array.from(new Set(ids)),
            timing: Timing.Runtime,
          },
          await identityApi.getCredentials(),
        );
      return new Map() as PolicyViolationsCountMap;
    }, [result.value]);

  const value = {
    view: view ?? 'cards',
    setView,
    environment,
    filters: completeFilters,
    setFilters,
    orderByOption: orderByOption ?? { field: 'rank', order: 'desc' },
    setOrderByOption,
    limit: limit ? Number(limit) : DEFAULT_LIMIT,
    setLimit: setLimitNumber,
    filterStateValid,
    cursor,
    setCursor,
    query,
    setQuery,
    policyViolationsInfo,
    filtersContext,
    setFavoriteFilter,
    favoriteFilter,
    ...result,
  };

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};
